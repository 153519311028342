import { FlexboxGrid } from "rsuite";
import styled, {css} from "styled-components";

export const FilterSectionFlexboxGrid = styled(FlexboxGrid)`
    && {
        ${props => !props.controlable ? css`
            display: flex;
            justify-content: space-between;

            margin-top: 15px;
            margin-bottom: 15px;
        ` : css`
        
        `}
    }
`
export const StyledItemFitWidth = styled(FlexboxGrid.Item)`
    &&& {

        ${props => props.isMobile ? css`
            width: 100%;

            .rs-form {
                display: block;
            }
            .rs-form-group {
                width: 100% !important;
                margin-bottom: 0 !important;
            }
            .rs-input, .rs-form-control-wrapper {
                width: 100% !important;
                margin-right: 0;
            }
            .rs-form-control-wrapper {
                margin-bottom: 15px;
            }
        ` : css`
            ${props => !props.controlable ? css`
                flex-grow: 1;
            ` : css`
            
            `}
        `}
    }
`;

export const StyledItemAutoMargin = styled(FlexboxGrid.Item)`
    && {
        margin-left: auto;

        ${props => props.isMobile ? css`
            display: block;
            flex: content;
    
            > * {
                width: 100%;
            }
            > *:not(:last-child) {
                margin-bottom: 10px;
            }
        ` : css`
            flex: 0 0 auto;

            > *:not(:last-child) {
                margin-right: 10px;
            }
        `}
    }
`;