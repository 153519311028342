import React, {useRef, useState, useEffect} from "react";
import {Alert, Button, FlexboxGrid, Form, FormControl, Icon, Schema, Tooltip, Whisper} from "rsuite";
import ModalEdit from "../Modal/ModalEdit";
import ModalEditProfileContactsLetter from "./ModalEditProfileContactsLetter";
import m from "definedMessages";
import { withTranslation } from "hoc/WithTranslation";
import { compareObjects, fullPhoneRegex, replaceUndefinedOrNull } from "utils";
import styled from "styled-components";
import { FilterSectionFlexboxGrid, StyledItemFitWidth } from "components/base/FitContainers";
import { useWindowWidth } from "hooks";

const {StringType} = Schema.Types;

const MIN_PASSWORD_LENGTH = 8;
const MAX_CHARACTERS = 40;

const MAX_MESSENGER_LENGTH = 40;



const ModalEditProfileContacts = ({
    maxWidth = 640,
    show,
    onClose,
    loading,
    contactList,
    contactLoading,
    
    getAccountContactList, 
    modifyAccountContact,
    modifyAccountContactRequest,
    setAccountContactLoadingData: setLoading,

    formatMessage,
    ...props
}) => {

    const contactsRefsData = {};

    const windowWidth = useWindowWidth();

    const isMobile = windowWidth < 768;

    const [showLetterModal, setShowLetterModal] = useState(false);
    const [letterModalData, setLetterModalData] = useState({
        id: null,
        name: null,
        email: null
    });

    const [contacts, setContacts] = useState(contactList || []);


    useEffect(() => {
        getAccountContactList();
    }, []);

    useEffect(() => {
        if (!contacts.length) {
            setContacts(contactList);
        }
    }, [contactList]);

    const updateRefsData = (ref, id) => contactsRefsData[id] = ref;

    const formProfileContactModel = Schema.Model({
        phone: StringType().addRule( value => fullPhoneRegex.test(value) , "Please enter valid phone number"),
        messenger: StringType()
            .maxLength(MAX_MESSENGER_LENGTH, formatMessage(m.maxLength, null, {count: MAX_MESSENGER_LENGTH})),
        telegram_username: StringType()
            .pattern(/.*\B@(?=\w{5,32}\b)[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*.*/, "Telegram username must be 5-32 long and contain the '@' symbol at the beginning.")    
    });

    const addableIsChanged = (id, addableObjectInitial, addableObject) => {
        const addedNew = addableObjectInitial && !addableObjectInitial.map((addable) => addable.id).includes(id);
        const changed = addableObjectInitial && !compareObjects(addableObjectInitial.find(obj => obj.id === id), addableObject);
        
        return addedNew || changed;
    };

    const onSubmit = () => {
        contacts.forEach((contact, idx) => {
            if (addableIsChanged(contact.id, contactList, contact)) {
                if (!(contactsRefsData[contact.id]).check()) {
                    return;
                }

                const data = JSON.stringify({
                    phone: contact?.phone,
                    messenger: contact?.messenger,
                    telegram_username: contact?.telegram_username,
                }, (_, value) => {
                    if (!value) {
                        return null;
                    }
                    
                    return value;
                });

                const clearedData = JSON.parse(data);
        
                modifyAccountContact(contact.id, clearedData)
                    .then((result) => {
                        if (Object.keys(result).length && !result?.error) {
                            Alert.success(`Contact ${contact.person} has been changed.`)
                        }
                    })
                    .finally(() => {
                        getAccountContactList();
                        
                        setLoading(false);
                    })
            }
        });

        return true;
    };

    const checkFormError = (errorObj) => {
        return Object.keys(errorObj).map(i => errorObj[i].hasError).every(value => value === false)
    };
    
    const updateFormValues = (formValues, id) => {
        contacts && setContacts([
            ...contacts.map(contact =>
                contact.id === id ? {...contact, ...formValues} : contact
            )
        ]);
    };


    return (
        <ModalEdit {...{show, onClose}}
            onSuccessClose={false}
            onClose={() => {
                onClose();
            }}
            onSuccess={() => onSubmit()}
            successButton={formatMessage(m.send)}
            // successButtonProps={{
            //     disabled:
            //         !checkFormError(errorValue)
            // }}
            title={"Account contacts"}
            width={maxWidth}
        >
            {contactList.map((contact, idx) => {

                return <Form
                    key={contact.id}
                    ref={ref => updateRefsData(ref, contact.id)}
                    model={formProfileContactModel}
                    onChange={(formValues) => {
                        const checkedData = formProfileContactModel.check(formValues);

                        if (checkedData) {
                            updateFormValues(formValues, contact.id);
                        }
                    }}
                    // formValue={contact}
                    formDefaultValue={contact}
                >
                    <FilterSectionFlexboxGrid controlable={!isMobile} style={{marginBottom: 10}}>
                        <StyledItemFitWidth isMobile={isMobile} controlable={!isMobile}>
                            <FormControl
                                name="person"
                                type="text"
                                disabled
                                style={{width: 160, marginRight: 10}}
                                placeholder={"Name"}
                                className="edit-profile-contact-input"
                            />
                        </StyledItemFitWidth>
                        <StyledItemFitWidth isMobile={isMobile} controlable={!isMobile}>
                            <Whisper
                                trigger="hover"
                                placement="auto"
                                speaker={<Tooltip>{contact?.email}</Tooltip>}
                            >
                                <FormControl
                                    name="email"
                                    type="text"
                                    disabled
                                    style={{width: 220, marginRight: 10}}
                                    placeholder={"Email"}
                                    className="edit-profile-contact-input"
                                />
                            </Whisper>
                        </StyledItemFitWidth>
                        <StyledItemFitWidth isMobile={isMobile} controlable={!isMobile}>
                            <Whisper
                                trigger="hover"
                                speaker={<Tooltip>Send request about changing contact name or email</Tooltip>}
                            >
                                <Button
                                    onClick={() => {
                                        setShowLetterModal(true);
                                        setLetterModalData({
                                            id: contact.id,
                                            person: contact.person,
                                            email: contact.email
                                        });
                                    }}
                                    // appearance="link"
                                    style={{width: 200}}
                                    color="violet"
                                    disabled={loading}
                                >
                                    <Icon icon="envelope"/>
                                    <span style={{marginLeft: 10}}>Change name or email</span>
                                </Button>
                            </Whisper>
                        </StyledItemFitWidth>
                    </FilterSectionFlexboxGrid>
                    <FilterSectionFlexboxGrid controlable={!isMobile}  style={{marginBottom: 20}}>
                        <StyledItemFitWidth isMobile={isMobile} controlable={!isMobile}>
                            <FormControl
                                name="phone"
                                type="text"
                                errorPlacement="auto"
                                style={{width: 160, marginRight: 10}}
                                placeholder={formatMessage(m.phone)}
                                className="edit-profile-contact-input"
                            />
                        </StyledItemFitWidth>
                        <StyledItemFitWidth isMobile={isMobile} controlable={!isMobile}>
                            <FormControl
                                name="messenger"
                                type="text"
                                errorPlacement="auto"
                                style={{width: 220, marginRight: 10}}
                                placeholder={"Messenger"}
                                className="edit-profile-contact-input"
                            />
                        </StyledItemFitWidth>
                        <StyledItemFitWidth isMobile={isMobile} controlable={!isMobile}>
                            <FormControl
                                name="telegram_username"
                                type="text"
                                errorPlacement="bottomEnd"
                                style={{width: 200}}
                                placeholder={"Telegram"}
                                className="edit-profile-contact-input"
                            />
                        </StyledItemFitWidth>
                    </FilterSectionFlexboxGrid>
                </Form>
            })}
            {showLetterModal && <ModalEditProfileContactsLetter
                show={showLetterModal}
                onClose={()=> {
                    setShowLetterModal(false);
                }}
                setLoading={setLoading}
                defaultValue={letterModalData}
                onSuccess={modifyAccountContactRequest}
            />}
        </ModalEdit>
        );
};


export default withTranslation(ModalEditProfileContacts);

const StyledFieldWrapper = styled(FlexboxGrid.Item)`
    && {
        margin-right: 10px;
    }
`;