import React, {useRef, useMemo, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {
    Alert,
    DatePicker,
    FlexboxGrid,
    Form,
    FormGroup,
    FormControl,
    Radio,
    RadioGroup,
    Schema,
    SelectPicker,
    List,
    Input,
    InputNumber,
    CheckPicker,
    Tooltip
} from "rsuite";
import Text, {str40} from "components/Form/Text";
import ServicePlans from "components/Filters/ServicePlans";
import CustomField from "components/Form/CustomField";
import Protocols from "components/Filters/Protocols";
import {BaseToggle} from "components/base/BaseToggle";
import {connect} from "react-redux";
import {DEFAULT_PRT_KEY} from "const";
import {fixedFloat, servicePick, copyToClipboard, toStartDayUTCTime} from "utils";
import CheckboxControl from "components/Form/CheckboxControl";
import styled, {createGlobalStyle, css} from "styled-components";
import BillingIncrement from "components/Filters/BillingIncrement"
import Button from "rsuite/es/Button";
import Icon from "rsuite/es/Icon";
import Popover from "rsuite/es/Popover";
import Whisper from "rsuite/es/Whisper";
import ColorBadge from "components/base/ColorBadge";

import m from "definedMessages";

import "jsoneditor/dist/jsoneditor.css";
import { usePrevious } from "hooks";
import {servicePlanNoTrunkFilter} from "utils/filters";


const {StringType, NumberType} = Schema.Types;


const formSmsModel = Schema.Model({
    name: str40,
    sp_key: NumberType()
        .isRequired("Required")
});


const formSmsSupplierModel = Schema.Model({
    plan_id: StringType()
        .isRequired("Required"),
    dlr_percent: NumberType()
        .isInteger("Precent should be integer")

});


const formDialerModel = Schema.Model({
    tprefix: StringType()
        .pattern(/^\d*[#]?\d*$/, "Techprefix can contain only numbers and one character\"#\"")
        .maxLength(10, "Technical prefix can contain a maximum of 10 characters"),
    port: NumberType()
        .isRequired("Required")
        .range(0, 65535, "Enter the correct port"),
    ip: StringType()
        .isRequired("Required")
        .pattern(
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            "Please enter legal IP Address"
        ),
    prt_key: NumberType().isRequired("Required")
});



const formHttpIpModel = Schema.Model({
    ip: StringType()
        .isRequired("Required")
        .pattern(
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            "Please enter legal IP Address"
        )
});

const dayModel = Schema.Model({
    day: NumberType()
        .min(1, "Minimum 1")
        .max(10000000, "Maximum 10000000")
        .isRequired("This field is required"),
});

const hourModel = Schema.Model({
    hour: NumberType()
        .min(1, "Minimum 1")
        .max(10000000, "Maximum 10000000")
        .isRequired("This field is required"),
});

const supplierIdModel = Schema.Model({
    senderid: StringType()
        .maxLength(40, "Maximum 40"),
});


const nonDialerTrafficRouteModel = Schema.Model({
    organic_password: StringType()
        .isRequired("Required")
        .maxLength(9, "Max length is 9 characters")
        .minLength(4, "Min length is 4 characters"),
    organic_login: StringType()
        .isRequired("Required")
        .maxLength(16, "Max length is 16 characters")
        .minLength(4, "Min length is 4 characters"),
    organic_ip: StringType()
        .isRequired("Required")
        .pattern(
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            "Please enter legal IP Address"
        ),
    organic_port: NumberType()
        .isRequired("Required")
        .range(0, 65535, "Enter the correct port"),
});


const TRAFFIC_LIMIT_UNEDITABLE = 0;
const TRAFFIC_LIMIT_CREATING_CODE = 1;
const TRAFFIC_LIMIT_EDITING_CODE = 2;


const TrunkForm  =  (
    {
        disabled,
        setDisabled,
        updateFormRef,
        updateFormRef2,
        updateFormRef3,
        updateFormRef4,
        is_default,
        onChangeDefault,
        initialValues,
        defaultSPKey,
        newTrunk,
        isMobile,
        error,
        account,
        service,
        setError,
        ivr_list,
        ipList = [],
        setIpList,
        httpIpList = [],
        setHttpIpList,
        ipOptionList=[],
        isGanTrunks,
        isTransitTrunks,
        protocolTypes,
        suppliersPlanList,
        accountServicePlanList = [],
        moreOptionsFlag,
        setMoreOptionsFlag,
        setMoreData,
        onPostbackTest,
        protocolType,
        setProtocolType,
        formTrafficLimitRef,
        trafficLimitKeyList,
        setTrafficLimitKeyList,
        rerouteStatusList = [],
        initTrafficLimitList,
        protocolTypeValues
    }
) => {
    
    const {formatMessage} = useIntl();

    const formMoreModel = Schema.Model({
        postback_url: StringType()
            .pattern(
            /http(s)*:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#&={}]*)?/
            , "Please enter a valid url")
            .addRule((value) => {
                const questionMarkRegex = /\?+/g;
                const filteredUrlString = value.replace(questionMarkRegex, "?");

                const correctValuesList = service 
                    ? ["{{cdrid}}", "{{calling_number}}", "{{called_number}}", "{{rate}}", "{{duration}}", "{{payout}}", "{{calltime}}", "{{additional_tones}}"]
                    : ["{{smsid}}", "{{senderid}}", "{{smstime}}", "{{payout}}", "{{called_number}}", "{{smstext}}"];
                const paramString = filteredUrlString.split("?")[1];
                const queryString = new URLSearchParams(paramString);
                const queryStringKeyList = Array.from(queryString.keys());

                const correctKeyList = queryStringKeyList.filter(key => {
                    const value = queryString.get(key);
                    const notConstValueRegex = /{{.*?}}/;
                    
                    const isNotConstValue = notConstValueRegex.test(value);
                    
                    if (!isNotConstValue) {
                        return true
                    } else if (isNotConstValue && correctValuesList.includes(value)) {
                        return true;
                    } else {
                        return false;
                    }
                });
    
                return correctKeyList.length === queryStringKeyList.length;
            }, "Please enter a valid parameters"),
        postback_method: StringType()
    });

    const formSupplierModel = useMemo(() => {
        return Schema.Model({
            passwd: StringType()
                .isRequired('Required')
                .maxLength(8, 'Max length is 8 characters')
                .minLength(4, 'Min length is 4 characters'),
            ...(protocolTypeValues.smpp_mo ? {
                port: NumberType()
                    .isRequired("Required")
                    .range(0, 65535, "Enter the correct port"),
            } : {}),
            login: StringType()
                .isRequired('Required')
                .maxLength(15, 'Max length is 15 characters')
                .minLength(4, 'Min length is 4 characters'),
            ip: StringType()
                .isRequired('Required')
                .pattern(
                    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                    'Please enter legal IP Address'
                )
        });
    }, [protocolTypeValues])
    
    const ipFormRef = {};

    const formContentModel = Schema.Model({
        postback_response_body: StringType()
    });

    const trafficLimitModel = isTransitTrunks 
        ? Schema.Model.combine(dayModel, supplierIdModel) 
        : Schema.Model.combine(dayModel, hourModel, supplierIdModel);

    const [chosenSpKey, setChosenSpKey] = useState( (initialValues && initialValues.sp_key) || 1 );
    const [chosenRrcKey, setChosenRrcKey] = useState( (initialValues && initialValues.rrc_key) || null );

    const [isResellerState, setIsResellerState] = useState( (initialValues && initialValues.is_reseller) || null);

    const [acceptedTrafficLimitList, setAcceptedTrafficLimitList] = useState(trafficLimitKeyList);

    const defaultTrafficLimitKeyList = trafficLimitKeyList.reduce((result, trafficLimit) => {
        result[trafficLimit.key] = {
            ...trafficLimit,
            editing: TRAFFIC_LIMIT_UNEDITABLE
        };
        return result;
    }, {});

    const [trafficLimitEditData, setTrafficLimitEditData] = useState(defaultTrafficLimitKeyList);


    const formModel = useMemo(() => {
        return Schema.Model({
            name: str40,
            sp_key: NumberType()
                .isRequired("Required"),
            rrc_key: NumberType()
                .isRequired("Required"),
            ...(!!isResellerState ? {
                subacc_rate_percent_month: NumberType()
                    .min(0, "Min number is 0")
                    .max(100, "Max number is 100")
                    .isRequired("Required"),
                subacc_rate_percent_week: NumberType()
                    .min(0, "Min number is 0")
                    .max(100, "Max number is 100")
                    .isRequired("Required")
            } : {})
        })
    }, [isResellerState]);
    

    useEffect(() => {
        setTrafficLimitKeyList(initTrafficLimitList);
        setTrafficLimitEditData(defaultTrafficLimitKeyList || {});
        setHttpIpList(initialValues?.ip_white_list || []);
        setIpList(initialValues?.ip_list || []);
    }, [protocolType]);

    useEffect(() => {
        ipList.forEach(({key}) => {
            ipFormRef[key] = {};
        })
    }, [ipList]);

    useEffect(() => {
        if (["smpp", "smpp_mo"].includes(protocolType)) {
            ipList.forEach(({key}) => {
                if (Object.keys(ipFormRef[key]).length && !ipFormRef[key].check()) {
                    const errorFormValue = ipFormRef[key].getFormValue();

                    const checkedFormValue = formSupplierModel.check(errorFormValue);
                    const hasErrorFormValue = Object.keys(checkedFormValue).filter(checkedKey => {
                        const checkedValue = checkedFormValue[checkedKey];

                        return checkedValue?.hasError;
                    });

                    setError((prevValue) => {
                        const prevErrors = (prevValue["ipErrors"] || {});
                        const prevFilteredErrors = Object.keys(prevErrors).filter((ipKey) => {
                            return parseInt(ipKey) !== parseInt(key)
                        })
                        .reduce((result, key) => {
                            result[key] = prevErrors[key];
                            return result;
                        }, {});

                        return {
                            ...prevValue,
                            "ipErrors": { 
                                ...prevFilteredErrors,
                                [key]: hasErrorFormValue
                            }
                        }
                    });
                }
            });
        }
    }, [protocolType])

    const defaultFormValue = {
        // sp_key: defaultSPKey,
        active: true,
        smpp_reroute_status_key: 11,
        delivery_delay_type: 0,
        message_protocol: protocolType,

        ...initialValues,
        is_default_term_point: is_default,
        dlr_percent: (!newTrunk ? initialValues?.dlr_percent || 0 : 0.97) * 100,
        ...(!!initialValues?.is_reseller ? {
            subacc_rate_percent_month: fixedFloat((initialValues?.subacc_rate_percent_month || 0) * 100),
            subacc_rate_percent_week: fixedFloat((initialValues?.subacc_rate_percent_week || 0) * 100)
        } : {})
    };

    const excludedSpList = useMemo(() => {
        if (initialValues.id) {
            return [];
        }
        return accountServicePlanList.filter(item => !item.allowed).map(item => item.sp_key);
    }, [initialValues, accountServicePlanList]);

    const isSupplierTrunk = (isTransitTrunks  || isGanTrunks);

    const formModelValue = service ? formModel : isSupplierTrunk ? formSmsSupplierModel : formSmsModel;
   
    const voiceParametersInfo = (
        <Popover title="Parameters">
            <p>Our ip for postback is 51.38.107.49</p>
            <p><b>{"{{cdrid}}"}</b> - Call id</p>
            <p><b>{"{{calling_number}}"}</b> - A-number</p>
            <p><b>{"{{called_number}}"}</b> - B-number</p>
            <p><b>{"{{rate}}"}</b> - Terminator rate</p>
            <p><b>{"{{duration}}"}</b> - Tariffed call duration </p>
            <p><b>{"{{payout}}"}</b> - Cost per side of the terminator</p>
            <p><b>{"{{calltime}}"}</b> - Call initialization time </p>
            <p><b>{"{{additional_tones}}"}</b> - Additional tones </p>
        </Popover>
    );

    const smsParametersInfo = (
        <Popover title="Parameters">
            <p>Our ip for postback is 51.38.107.49</p>
            <p><b>{"{{smsid}}"}</b> - SMS id</p>
            <p><b>{"{{senderid}}"}</b> - Sender id</p>
            <p><b>{"{{smstime}}"}</b> - SMS time</p>
            <p><b>{"{{payout}}"}</b> - Payout</p>
            <p><b>{"{{called_number}}"}</b> - Called number</p>
            <p><b>{"{{smstext}}"}</b> - SMS text</p>
        </Popover>
    );

    const TrafficLimitTable = isSupplierTrunk && <>
        <Header>SMS limit per one number</Header>

        {trafficLimitKeyList && trafficLimitKeyList.length ? <StyledFlexboxGrid justify="start">
                <FlexboxGrid.Item style={{minWidth: "228px"}} colspan={1}>Senderid</FlexboxGrid.Item>
                <FlexboxGrid.Item style={{minWidth: "100px"}} colspan={1}>Day</FlexboxGrid.Item>
                {!isTransitTrunks ? <FlexboxGrid.Item style={{minWidth: "100px"}} colspan={1}>Hour</FlexboxGrid.Item> : <></>}
            </StyledFlexboxGrid>
        : <></>}

        <StyledGrid
            isMobile={isMobile}
            style={{margin: 0, marginLeft: 30}}
        >
            <StyledList>
                {trafficLimitKeyList.map((trafficLimit, idx) => {
                    const editableRowsDisabled = !([TRAFFIC_LIMIT_CREATING_CODE, TRAFFIC_LIMIT_EDITING_CODE].includes(trafficLimitEditData[trafficLimit.key]?.editing));
                    const currentFormValue = trafficLimitEditData[trafficLimit.key];
                    const defaultTrafficLimit = initTrafficLimitList.find((initTrafficLimitValue) => {
                        return initTrafficLimitValue.key === trafficLimit.key;
                    }) || {};


                    const ganDisabled = defaultTrafficLimit.senderid === trafficLimit.senderid 
                        && defaultTrafficLimit.day === trafficLimit.day 
                        && defaultTrafficLimit.hour === trafficLimit.hour 
                        || (!trafficLimit.day || !trafficLimit.hour);
                    const transitDisabled =  defaultTrafficLimit.senderid === trafficLimit.senderid &&
                        defaultTrafficLimit.day === trafficLimit.day || !trafficLimit.day;
                    const savedButtonDisabled = isTransitTrunks ? transitDisabled : ganDisabled;

                    return (<StyledListItem>
                        <TrafficLimitForm
                            ref={ref => formTrafficLimitRef[trafficLimit.key] = ref}
                            model={trafficLimitModel}
                            onChange={(formValue) => {
                                const currentFormValue = {...trafficLimit, ...formValue};
                                const checkedFormValue = trafficLimitModel.check(currentFormValue);
                                const hasErrorFormValue = Object.keys(checkedFormValue).filter(key => {
                                    const checkedValue = checkedFormValue[key];

                                    return checkedValue?.hasError;
                                });

                                setError((prevValue) => {
                                    const prevErrors = (prevValue["trafficLimitErrors"] || {});
                                    const prevFilteredErrors = Object.keys(prevErrors).filter((ipKey) => {
                                        return parseInt(ipKey) !== parseInt(trafficLimit.key)
                                    })
                                    .reduce((result, key) => {
                                        result[key] = prevErrors[key];
                                        return result;
                                    }, {});
            
                                    return {
                                        ...prevValue,
                                        "trafficLimitErrors": { 
                                            ...prevFilteredErrors,
                                            [trafficLimit.key]: hasErrorFormValue
                                        }
                                    }
                                });
                                setTrafficLimitKeyList((prevTrafficLimit) => {
                                    const prevList = prevTrafficLimit.map((x) => x);
                                    prevList[idx] = {
                                        ...prevList[idx],
                                        ...formValue,
                                        ...(formValue?.day ? {day: parseInt(formValue.day)} :  {day: formValue.day}),
                                        ...(formValue?.hour ? {hour: parseInt(formValue.hour)} :  {hour: formValue.hour}),
                                    };
                                    return prevList;
                                });
            
                                setTrafficLimitEditData((prevTrafficLimit) => {
                                    const currentPrevTrafficLimit = {...prevTrafficLimit};
                                    currentPrevTrafficLimit[formValue.key] = {
                                        ...currentPrevTrafficLimit[formValue.key],
                                        ...formValue,
                                        ...(formValue?.day ? {day: parseInt(formValue.day)} :  {day: formValue.day}),
                                        ...(formValue?.hour ? {hour: parseInt(formValue.hour)} :  {hour: formValue.hour}),
                                    };
                                    return currentPrevTrafficLimit;
                                });
                            }}
                            formValue={currentFormValue}
                        >
                            <span className="index">{idx + 1}</span>
                            <StyledField style={{width: 220}}>
                                <FormControl
                                    // accepter={Input}
                                    value={trafficLimit.senderid}
                                    name="senderid"
                                    placeholder="Senderid"
                                    errorPlacement="topEnd"
                                    disabled={![TRAFFIC_LIMIT_CREATING_CODE].includes(currentFormValue?.editing)}
                                />
                            </StyledField>
                            <StyledField style={{width: 80}}>
                                <FormControl
                                    // accepter={Input}
                                    value={trafficLimit.day}
                                    name="day"
                                    placeholder="Day"
                                    errorPlacement="topEnd"
                                    disabled={editableRowsDisabled}
                                />
                            </StyledField>
                            {!isTransitTrunks ? <StyledField style={{width: 80}}>
                                <FormControl
                                    // accepter={Input}
                                    value={trafficLimit.hour}
                                    name="hour"
                                    placeholder="Hour"
                                    errorPlacement="topEnd"
                                    disabled={editableRowsDisabled}
                                />
                            </StyledField> : <></>}
                            <StyledButtonContainer style={{width: 120}}>
                            {([TRAFFIC_LIMIT_CREATING_CODE, TRAFFIC_LIMIT_EDITING_CODE].includes(trafficLimitEditData[trafficLimit.key]?.editing))
                                && Object.keys(trafficLimitEditData[trafficLimit.key]).length ? <>
                                    <SaveButton
                                        appearance="ghost"
                                        onClick={async () => {
                                            // const currentTrafficLimitValue = trafficLimitEditData[trafficLimit.key];
                                            const currentTrafficLimit = {
                                                key: trafficLimit?.key,
                                                senderid: trafficLimit.senderid || "",
                                                day: parseInt(trafficLimit.day || 0) ,
                                                ...(!isTransitTrunks ? {hour: parseInt(trafficLimit.hour || 0)} : {})
                                            };
                           
                                            const currentAcceptedTrafficLimitList = trafficLimitKeyList.reduce((result, value, idx) => {
                                                result[idx] = value;

                                                if (value.key === trafficLimit.key) {
                                                    result[idx] = currentTrafficLimit;
                                                }

                                                return result;
                                            }, []);

                                            setAcceptedTrafficLimitList(currentAcceptedTrafficLimitList);

                                            setTrafficLimitKeyList(prev => {
                                                const prevTrafficLimit = prev.filter(prevTrafficLimitValue => prevTrafficLimitValue.key !== currentTrafficLimit.key);
                                                const finalTrafficLimitList = [...prevTrafficLimit, currentTrafficLimit].sort((a, b) => {
                                                    return a.key > b.key;
                                                });
                                                return finalTrafficLimitList;
                                            });
                        
                                            setTrafficLimitEditData((prevTrafficLimit) => {
                                                const currentPrevTrafficLimit = {...prevTrafficLimit};
                                                currentPrevTrafficLimit[trafficLimit.key] = {
                                                    ...currentPrevTrafficLimit[trafficLimit.key],
                                                    ...trafficLimit,
                                                    editing: TRAFFIC_LIMIT_UNEDITABLE
                                                };
                                                return currentPrevTrafficLimit;
                                            });

                                        }}
                                        disabled={savedButtonDisabled}
                                    >
                                        <Icon icon="check-circle"/>
                                    </SaveButton>
                                    <CancelButton
                                        appearance="ghost"
                                        onClick={() => {
                                            const currentFormValue = trafficLimitEditData[trafficLimit.key];
                                            if (
                                                [TRAFFIC_LIMIT_CREATING_CODE].includes(trafficLimitEditData[trafficLimit.key]?.editing)
                                                // && (!currentFormValue?.hour && !currentFormValue?.day && !currentFormValue?.senderid)
                                            ) {
                                                const currentTrafficLimitKeyList = trafficLimitKeyList.map((x) => x);;
                                                currentTrafficLimitKeyList.splice(idx, 1);
            
                                                setTrafficLimitKeyList(currentTrafficLimitKeyList);
                                                setTrafficLimitEditData((prevTrafficLimit) => {
                                                    const currentTrafficLimit = {...prevTrafficLimit};
                                                    delete currentTrafficLimit[trafficLimit.key];
                                                    delete currentTrafficLimit[undefined];
                                                    return currentTrafficLimit;
                                                })
                                            } else {
                                                const canceledTrafficLimitList = trafficLimitKeyList.reduce((result, value, idx) => {
                                                    if (value.key !== trafficLimit.key) {
                                                        result[idx] = value;
                                                    } else {
                                                        const acceptedTrafficLimit = acceptedTrafficLimitList.find((acceptedValue) => {
                                                            return acceptedValue.key === trafficLimit.key
                                                        });
                                                        result[idx] = acceptedTrafficLimit;
                                                    }

                                                    return result;
                                                }, []);

                                                setTrafficLimitKeyList(canceledTrafficLimitList);
                                                setTrafficLimitEditData((prevTrafficLimitValue) => {
                                                    const currentPrevTrafficLimit = {...prevTrafficLimitValue};
                                                   
                                                    const currentAcceptedTrafficLimit = acceptedTrafficLimitList.find((acceptedTrafficLimit) => {
                                                        return acceptedTrafficLimit.key === trafficLimit.key
                                                    }) || {};

                                                    currentPrevTrafficLimit[trafficLimit.key] = {
                                                        // NOT RIGHT, YOU NEED LAST VERSION OF TRAFFIC ONLY AFTER ACCEPT, NOT PREVIOUS VERSION AFTER EACH UPDATE
                                                        ...currentAcceptedTrafficLimit,
                                                        editing: TRAFFIC_LIMIT_UNEDITABLE
                                                    };

                                                    delete currentPrevTrafficLimit[undefined];

                                                    return currentPrevTrafficLimit;
                                                });
                                            }
                                        }}
                                    >
                                        <Icon icon="close-circle"/>
                                    </CancelButton>
                                </> : [TRAFFIC_LIMIT_UNEDITABLE].includes(trafficLimitEditData[trafficLimit.key]?.editing) &&
                                <EditButton
                                    appearance="ghost"
                                    onClick={() => {
                                        setTrafficLimitEditData((prevTrafficLimit) => {
                                            const currentPrevTrafficLimit = {...prevTrafficLimit};
                                            currentPrevTrafficLimit[trafficLimit.key] = {
                                                ...currentPrevTrafficLimit[trafficLimit.key],
                                                editing: TRAFFIC_LIMIT_EDITING_CODE
                                            };
                                            return currentPrevTrafficLimit;
                                        });
                                    }}
                                >
                                    <Icon icon="edit"/>
                                </EditButton>} 
                                {!(trafficLimit.key && trafficLimit.key.toString().startsWith("new")
                                    || idx === 0 && trafficLimit.senderid === null) ? <Icon
                                    onClick={() => {
                                        const currentTrafficLimitKeyList = trafficLimitKeyList.map((x) => x);

                                        currentTrafficLimitKeyList.splice(idx, 1);

                                        setTrafficLimitKeyList(currentTrafficLimitKeyList);
                                        setAcceptedTrafficLimitList(currentTrafficLimitKeyList);
                                        setTrafficLimitEditData((prevTrafficLimit) => {
                                            const currentTrafficLimit = {...prevTrafficLimit};
                                            delete currentTrafficLimit[trafficLimit.key];
                                            return currentTrafficLimit;
                                        })
                                    }}
                                    className="delete_traffic"
                                    icon="trash"
                                /> : <></>}
                            </StyledButtonContainer>
                        </TrafficLimitForm>
                    </StyledListItem>)
                })}
                <AddNewButton
                    appearance="ghost"
                    onClick={() => {
                        const keySortedList = Object.keys(trafficLimitEditData).filter(key => key.startsWith("new")).sort((a, b) => a > b);

                        const biggestKeySplittedList = keySortedList.length ? keySortedList[keySortedList.length - 1].split("new") : [];
                        const biggestKey = biggestKeySplittedList.length ? biggestKeySplittedList[biggestKeySplittedList.length - 1] : 0;
                        const biggestKeyCount = biggestKey ? parseInt(biggestKey) : 0;
                        const newKeyCount = biggestKeyCount + 1;
                        const newKey = `new${newKeyCount || ""}`;
                        
                        const newTrafficLimit = {
                            key: newKey, hour: null, day: null, senderid: "", editing: TRAFFIC_LIMIT_CREATING_CODE
                        };

                        const newTrafficLimitKeyList = [...trafficLimitKeyList, newTrafficLimit].sort((a, b) => {
                            return a.key > b.key;
                        });

                        setTrafficLimitKeyList(newTrafficLimitKeyList);
                        // setAcceptedTrafficLimitList(newTrafficLimitKeyList)

                        setTrafficLimitEditData((prevTrafficLimit) => {
                            const currentPrevTrafficLimit = {...prevTrafficLimit};
                            currentPrevTrafficLimit[newKey] = newTrafficLimit;
                            return currentPrevTrafficLimit;
                        });
                    }}
                    // disabled={!initialValues.id}
                >
                    <Icon icon="plus"/> Add new{trafficLimitKeyList && !trafficLimitKeyList.length ? " traffic limit" : ""}
                </AddNewButton>

            </StyledList>
        </StyledGrid>
    </>;

    const deliveryDelayTypeData = [
        {name: "No delay", value: 0},
        {name: "Simulation of real delay", value: 1},
        {name: "80% 2-6 seconds", value: 2}
    ];

    const customRerouteStatusList = rerouteStatusList.map((value) => {
        return {
            name: `(${value.key}) ${value.smpp_reroute_status_name}`,
            key: value.key
        }
    });

    const isOneOfSmpp = protocolTypeValues.smpp || protocolTypeValues.smpp_mo;

    return (
        <Form
            layout={isMobile ? null : "inline"}
            model={formModelValue}
            formDefaultValue={defaultFormValue}
            ref={updateFormRef}
            style={{margin: 0, width: "100%"}}
        >
            <FlexboxGrid
                align={isMobile ? "flex-start" : "center"}
                style={{margin: 0, width: "100%"}}
            >
                <FlexboxGrid.Item className={"mb-2"}>
                    {!isSupplierTrunk 
                    ? <>
                        <Text
                            name="name"
                            placeholder="Name"
                            style={{width: 200, marginLeft: 0}}
                        />
                        <ServicePlans
                            classPrefix={"rs-form-control"}
                            excluded={excludedSpList}
                            disabled={!!initialValues.id}
                            style={{verticalAlign: "none", marginLeft: 10, paddingRight: 10, height: 36}}
                            filterFunc={servicePlanNoTrunkFilter}
                            onChange={(value) => {
                                if (chosenSpKey === value)
                                    return;
                                setChosenSpKey(value);
                            }}
                        />
                        <CheckboxControl
                            name="active"
                            defaultChecked={defaultFormValue.active}
                        >
                            Active
                        </CheckboxControl>
                    </>
                    : <></>}
                    {service && account.is_reseller && <CheckboxControl
                        name="is_reseller"
                        defaultChecked={false}
                        disabled={initialValues.id}
                        checked={initialValues.is_reseller}
                        onChange={(value) => {
                            setIsResellerState(value);
                        }}
                    >
                        Reseller trunk
                    </CheckboxControl>}
                </FlexboxGrid.Item>
            </FlexboxGrid>


            <StyledGrid
                isMobile={isMobile}
                align={"flex-start"} //"center"
                style={isSupplierTrunk ? {paddingLeft: 80, width: "100%"} : {display: "flex", justifyContent: "center", width: "100%"}}
            >
                {service ? <FlexboxGrid.Item className={!isMobile ? "mr-4" : ""}>
                    <BillingIncrement
                        classPrefix={"rs-form-control"}
                        style={{verticalAlign: "none", marginLeft: 10, paddingRight: 10, height: 36}}
                        onChange={(value) => {
                            if (chosenRrcKey === value)
                                return;
                            setChosenRrcKey(value);
                        }}
                        label="Billing Increment"
                    />
                </FlexboxGrid.Item> : <></>}
                {isSupplierTrunk ? <FlexboxGrid.Item className={!isMobile ? "mr-4" : ""}>
                    <CustomField
                        block
                        data={suppliersPlanList}
                        accepter={SelectPicker}
                        labelKey = "name"
                        valueKey = "id"
                        placeholder="Trunk Type..."
                        name="plan_id"
                        searchable={false}  
                        cleanable={false}  
                        // classPrefix="allocated"
                        classPrefix={"rs-form-control"}
                        virtualized={false}
                        label="Trunk Type"
                    />
                </FlexboxGrid.Item> : <></>}
                <FlexboxGrid.Item className={!isMobile ? "mr-4" : ""}>
                    {/* {isMobile && <p>Start Date</p>} */}
                    <CustomField
                        name="start_date"
                        placeholder="Start Date"
                        cleanable={false}
                        accepter={DatePicker}
                        // className={"mr-2"}
                        style={{width: 165}}
                        ranges={[
                            {
                                label: "Today",
                                value: new Date()
                            },
                            {
                                label: "Tomorrow",
                                value: new Date( new Date().setDate(new Date().getDate() + 1) )
                            }
                        ]}

                        // mobile
                        inline={isMobile}
                        oneTap={isMobile}
                        label={servicePick(service, "", "Start Date")}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    {/* {isMobile && initialValues.id &&
                        <p>End Date</p>
                    } */}
                    {isMobile && !initialValues.id
                        ? null
                        : <CustomField
                        name="end_date"
                        placeholder="End Date"
                        accepter={DatePicker}
                        style={{width: 165}}
                        disabled={!initialValues.id}
                        ranges={[
                            {
                                label: "Today",
                                value: new Date()
                            },
                            {
                                label: "Tomorrow",
                                value: new Date( new Date().setDate(new Date().getDate() + 1) )
                            }
                        ]}

                        // mobile
                        inline={isMobile}
                        oneTap={isMobile}
                        label={servicePick(service, "", "End Date")}
                    />}
                </FlexboxGrid.Item>
                {!!isResellerState && <FlexboxGrid.Item style={{marginRight: "10px"}}>
                    <CustomField
                        name="subacc_rate_percent_month"
                        placeholder="Monthly rate percent"
                        label="Reseller's"
                        width={155}
                    />
                </FlexboxGrid.Item>}
                {!!isResellerState && <FlexboxGrid.Item>
                    <CustomField
                        name="subacc_rate_percent_week"
                        placeholder="Weekly rate percent"
                        width={155}
                    />
                </FlexboxGrid.Item>}

                {isSupplierTrunk ? <FlexboxGrid.Item className={"ml-2"}>
                    <CheckboxControl
                        name="active"
                        defaultChecked={defaultFormValue.active}
                    >
                        Active
                    </CheckboxControl>
                </FlexboxGrid.Item> : <></>}
                {isTransitTrunks ? <FlexboxGrid.Item className={"ml-2"}>
                    <Whisper
                        trigger="active"
                        speaker={<Tooltip>If this option enabled, the traffic will come only through prefixes and won"t come by mcc/mnc codes.</Tooltip>}
                        placement="auto"
                    >
                        <CheckboxControl
                            name="custom_ranges"
                            defaultChecked={defaultFormValue.custom_ranges}
                            disabled={!newTrunk}
                        >
                            Custom ranges trunk
                        </CheckboxControl>
                    </Whisper>
                </FlexboxGrid.Item> : <></>}
            </StyledGrid>
        
            {isSupplierTrunk && <StyledFlexboxGrid justify="start">
                {/* <BaseToggle
                    checkedChildren="HTTP Protocol"
                    unCheckedChildren="SMPP Protocol"
                    defaultChecked={protocolType}
                    onChange={value => setProtocolType(value)}
                 /> */}

                <CustomField
                    style={{minWidth: 160}}
                    accepter={SelectPicker}
                    data={[
                        {
                            key: "smpp",
                            name: "SMPP"
                        },
                        {
                            key: "http/https",
                            name: "HTTP"
                        },
                        {
                            key: "smpp_mo",
                            name: "SMPP (MO)"
                        },
                    ]}
                    labelKey = "name"
                    valueKey = "key"
                    name="message_protocol"
                    onChange={(key) => {
                        setProtocolType(key);
                    }}
                    searchable={false}
                    cleanable={false}
                    label="Protocol type"
                />
            </StyledFlexboxGrid>}

            {isSupplierTrunk && isOneOfSmpp && <StyledFlexboxGrid justify="start">
                <FlexboxGrid.Item className={"mb-2"}>
                    <CustomField
                        block
                        data={customRerouteStatusList}
                        accepter={SelectPicker}
                        labelKey = "name"
                        valueKey = "key"
                        placeholder="Comand status..."
                        name="smpp_reroute_status_key"
                        searchable={false}  
                        cleanable={false}  
                        // classPrefix="allocated"
                        classPrefix={"rs-form-control"}
                        virtualized={false}
                        label="SMPP Rerouting"
                    />
                </FlexboxGrid.Item>
                {protocolTypeValues.smpp && <FlexboxGrid.Item className={"mb-2 ml-4"}>
                    <CustomField
                        block
                        data={deliveryDelayTypeData}
                        accepter={SelectPicker}
                        labelKey = "name"
                        valueKey = "value"
                        placeholder="Type..."
                        name="delivery_delay_type"
                        searchable={false}  
                        cleanable={false}  
                        // classPrefix="allocated"
                        classPrefix={"rs-form-control"}
                        virtualized={false}
                        label="Delivery Delay Type"
                    />
                </FlexboxGrid.Item>}
                {protocolTypeValues.smpp && <FlexboxGrid.Item className={"mb-2 ml-4"}>
                    <CustomPercentField
                        block
                        accepter={InputNumber}
                        placeholder="%"
                        name="dlr_percent"
                        cleanable={false}  
                        // classPrefix="allocated"
                        classPrefix={"rs-form-control"}
                        virtualized={false}
                        label="DLR Percent"
                        min={0}
                        max={100}
                        step={1}
                    />
                </FlexboxGrid.Item>}
            </StyledFlexboxGrid>}
            {isSupplierTrunk && isOneOfSmpp && <StyledFlexboxGrid justify="start" ml={70}>
                <FlexboxGrid.Item className={"mb-2"}>
                    <CheckboxControl
                        name="bill_only_delivered"
                        defaultChecked={defaultFormValue.bill_only_delivered}
                    >
                        Bill only delivered SMS
                    </CheckboxControl>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className={"mb-2 ml-4"}>
                    <CheckboxControl
                        name="block_traffic_limit_excess"
                        defaultChecked={defaultFormValue.block_traffic_limit_excess || false}
                    >
                        Block traffic limit excess
                    </CheckboxControl>
                </FlexboxGrid.Item>
            </StyledFlexboxGrid>}
            
            {isSupplierTrunk && isOneOfSmpp && <>
                <Header>IP List</Header>

                {isSupplierTrunk && ipList && ipList.length ? <StyledFlexboxGrid justify="start">
                        <FlexboxGrid.Item style={{width: 165, marginRight: 20}}>IP</FlexboxGrid.Item>
                        {protocolTypeValues.smpp_mo && <FlexboxGrid.Item style={{width: 120, marginRight: 20}}>Port</FlexboxGrid.Item>}
                        <FlexboxGrid.Item style={{width: 165, marginRight: 20}}>Login</FlexboxGrid.Item>
                        <FlexboxGrid.Item style={{width: 165, marginRight: 20}}>Password</FlexboxGrid.Item>
                        <FlexboxGrid.Item style={{width: 165, marginRight: 20}}>Start date</FlexboxGrid.Item>
                        <FlexboxGrid.Item style={{width: 165}}>End date</FlexboxGrid.Item>
                    </StyledFlexboxGrid>
                : <></>}

            <StyledGrid
                isMobile={isMobile}
                // align={isMobile ? "flex-start" : "center"}
                style={{margin: 0, marginLeft: 30}}
            >
                <StyledList>
                    {ipList.map((ipValue, idx) => {
                        const statusColor = ipValue.bound ? "var(--color-success)" : "var(--color-error2)";
                        const currentIpValue = {...ipValue, options: ipValue?.option_list ? ipValue.option_list.map(value => value.key) : []};
                        
                        return (<StyledListItem>
                            <Form
                                key={ipValue.key}
                                ref={ref => ipFormRef[ipValue.key] = ref}
                                model={formSupplierModel}
                                onChange={(value) => {
                                
                                    const checkedFormValue = formSupplierModel.check(value);
                                    const hasErrorFormValue = Object.keys(checkedFormValue).filter(key => {
                                        const checkedValue = checkedFormValue[key];

                                        return checkedValue?.hasError;
                                    });

                                    setError((prevValue) => {
                                        const ipValueKey = ipValue.key || `new_${idx}`;
                                        const prevErrors = (prevValue["ipErrors"] || {});
                                        const prevFilteredErrors = Object.keys(prevErrors).filter((ipKey) => {
                                            return parseInt(ipKey) !== parseInt(ipValueKey);
                                        })
                                        .reduce((result, key) => {
                                            result[key] = prevErrors[key];
                                            return result;
                                        }, {});
                
                                        return {
                                            ...prevValue,
                                            "ipErrors": { 
                                                ...prevFilteredErrors,
                                                [ipValueKey]: hasErrorFormValue
                                            }
                                        }
                                    });
                                    // setDisabled(false)
                                    setIpList(prev => {
                                        const prevIpList = prev.map((x) => x);
                                        prevIpList[idx] = {
                                            ...value,
                                            ...(value?.port ? {port: value.port} : {}),
                                            ...(value?.start_date ? {start_date: toStartDayUTCTime(new Date(value?.start_date))} : {}),
                                            ...(value?.end_date ? {end_date: toStartDayUTCTime(new Date(value?.end_date))} : {})
                                        };
                                        return prevIpList;
                                    })
                                }}
                                formDefaultValue={{...currentIpValue}}
                            >
                                <FormGroup>
                                    {ipValue?.key && <span className="status">
                                        <ColorBadge badgeColor={statusColor}/>
                                    </span>}
                                    <span className="index">{idx + 1}</span>
                                    <FormControl
                                        name="ip"
                                        placeholder="0.0.0.0"
                                        style={{width: 165}}
                                        value={ipValue.ip}
                                        onChange={(value) => {
                                            // ipValue.ip = value;
                                        }}
                                    />
                                    {protocolTypeValues.smpp_mo && <FormControl
                                        name="port"
                                        placeholder="65535"
                                        style={{width: 120}}
                                        value={ipValue.port}
                                    />}
                                    {/* <span className="separator">@</span> */}
                                    <FormControl
                                        name="login"
                                        placeholder="Login"
                                        style={{width: 165}}
                                        value={ipValue.login}
                                        onChange={(value) => {
                                            // ipValue.login = value;
                                        }}
                                    />
                                    <FormControl
                                        name="passwd"
                                        placeholder="Password"
                                        style={{width: 165}}
                                        value={ipValue.passwd}
                                        onChange={(value) => {
                                            // ipValue.passwd = value;
                                        }}
                                    />
                                    <FormControl
                                        name="start_date"
                                        placeholder="Start Date"
                                        cleanable={false}
                                        accepter={DatePicker}
                                        style={{width: 165}}
                                        ranges={[
                                            {
                                                label: "Today",
                                                value: new Date()
                                            },
                                            {
                                                label: "Tomorrow",
                                                value: new Date( new Date().setDate(new Date().getDate() + 1) )
                                            }
                                        ]}
                                        value={ipValue.start_date}
                                        onChange={(value) => {
                                            // ipValue.start_date = toStartDayUTCTime(new Date(value));
                                        }}
                                        // mobile
                                        inline={isMobile}
                                        oneTap={isMobile}
                                    />
                                    <FormControl
                                        name="end_date"
                                        placeholder="End Date"
                                        accepter={DatePicker}
                                        style={{width: 165}}
                                        disabled={!initialValues.id}
                                        ranges={[
                                            {
                                                label: "Today",
                                                value: new Date()
                                            },
                                            {
                                                label: "Tomorrow",
                                                value: new Date( new Date().setDate(new Date().getDate() + 1) )
                                            }
                                        ]}
                                        value={ipValue.end_date}
                                        onChange={(value) => {
                                            // ipValue.end_date = toStartDayUTCTime(new Date(value));
                                        }}
                                        // mobile
                                        inline={isMobile}
                                        oneTap={isMobile}
                                    />
                                </FormGroup>
                                <Icon
                                    onClick={() => {
                                        const newList = ipList.map((x) => x);;
                                        newList.splice(idx, 1);

                                        setIpList(newList);
                                    }}
                                    className="delete"
                                    icon="trash"
                                />
                            </Form>
                        </StyledListItem>);
                        })}
                        <AddNewButton
                            
                            appearance="ghost"
                            onClick={() => {
                                const newList = [...ipList];
                                newList.push({ip: "", login: "", passwd: "", start_date: null, end_date: null});
                                setIpList(newList);
                            }}
                            // disabled={newRangeHasError}
                        >
                            <Icon icon="plus"/> Add new{ipList && !ipList.length ? " ip" : ""}
                        </AddNewButton>
                    </StyledList>
                </StyledGrid>
            </>}

            {protocolTypeValues.http && <>
                <Header>IP White List</Header>

                {isSupplierTrunk && <StyledGrid
                    isMobile={isMobile}
                    style={{margin: 0, marginLeft: 30}}
                >
                    <StyledList>
                        {httpIpList.map((httpIp, idx) => {
                            return <StyledListItem>
                                <Form
                                    model={formHttpIpModel}
                                    onChange={(value) => {
                                    
                                        const checkedFormValue = formHttpIpModel.check(value);
                                        const hasErrorFormValue = Object.keys(checkedFormValue).filter(key => {
                                            const checkedValue = checkedFormValue[key];

                                            return checkedValue?.hasError;
                                        });
                                        
                                        setError((prevValue) => {
                                            const prevErrors = (prevValue["ipHttpErrors"] || {});
                                            const prevFilteredErrors = Object.keys(prevErrors).filter((ip) => {
                                                return ip !== httpIp;
                                            })
                                            .reduce((result, ip) => {
                                                result[ip] = prevErrors[ip];
                                                return result;
                                            }, {});
                    
                                            return {
                                                ...prevValue,
                                                "ipHttpErrors": { 
                                                    ...prevFilteredErrors,
                                                    [httpIp]: hasErrorFormValue
                                                }
                                            }
                                        });
                                    }}
                                    formDefaultValue={{
                                        ip: httpIp
                                    }}
                                >
                                    <FormGroup>
                                        <span className="index">{idx + 1}</span>
                                        <FormControl
                                            name="ip"
                                            placeholder="0.0.0.0"
                                            style={{width: 165}}
                                            onChange={(value) => {
                                                setHttpIpList(prev => {
                                                    const currentHttpIpList = prev.map(x => x);
                                                    const head = currentHttpIpList.slice(0, idx);
                                                    const tail = currentHttpIpList.slice(idx+1, currentHttpIpList.length);

                                                    return [...head, value, ...tail];
                                                });
                                            }}
                                        />
                                    </FormGroup>
                                    <Icon
                                        onClick={() => {
                                            const newList = httpIpList.map((x) => x);
                                            newList.splice(idx, 1);

                                            setHttpIpList(newList);
                                        }}
                                        className="delete"
                                        icon="trash"
                                    />
                                </Form>
                            </StyledListItem>
                        })}
                        <AddNewButton 
                            appearance="ghost"
                            onClick={() => {
                                const newList = httpIpList.map((x) => x);
                                newList.push("");
                                setHttpIpList(newList);
                            }}
                            // disabled={newRangeHasError}
                        >
                            <Icon icon="plus"/> Add new{httpIpList && !httpIpList.length ? " http" : ""}
                        </AddNewButton>
                    </StyledList>
                </StyledGrid>}
            </>}

            {(!service && isSupplierTrunk && protocolTypeValues.http) && <FlexboxGrid.Item>
                <ContentSupplierForm
                    error={error}
                    setError={setError}
                    disabled = {disabled}
                    model={formContentModel}
                    setMoreData={setMoreData}
                    initialValues = {initialValues}
                    {...{
                        service,
                        formatMessage,
                        onPostbackTest,
                        isSupplierTrunk
                    }}
                />
            </FlexboxGrid.Item>}

            {isTransitTrunks && !protocolTypeValues.smpp_mo && <FlexboxGrid.Item>
                <Header>Non dialer traffic route 
                    {initialValues?.organic_bound ? <Whisper
                        speaker={<Tooltip>Bound</Tooltip>}
                        trigger="hover"
                    >
                        <Icon icon="link" style={{marginLeft: "5px"}}/>
                    </Whisper> : <></>}
                </Header>

                <NonDialerTrafficRoute
                     model={nonDialerTrafficRouteModel}
                     error={error}
                     disabled={disabled} 
                     formRef={updateFormRef4}
                     formDefaultValue={initialValues}
                 
                     setError={setError}
                />
            </FlexboxGrid.Item>}

            {TrafficLimitTable}

            <FlexboxGrid
                align={isMobile ? "flex-start" : "center"}
                style={{margin: 0, width: "100%"}}
            >
                {service ? <TermPointItem>
                    <CustomField
                        disabled={disabled}
                        accepter={RadioGroup}
                        onChange = {onChangeDefault}
                        name="is_default_term_point"
                        inline={isMobile ? false : true}
                        style={{marginLeft: "-10px"}}
                    >
                        <Radio value={true}>Default termination point</Radio>
                        <Radio value={false}>Dialer panel</Radio>
                    </CustomField>
                </TermPointItem> : <></>}
                {(service || !isSupplierTrunk || !is_default) &&
                    <FlexboxGrid.Item style={{minWidth: 400}}>
                        <DialerForm
                            initialValues={initialValues}
                            disabled={disabled}
                            formRef={updateFormRef2}
                            defaultSPKey={defaultSPKey}
                            showOnlyMoreOption={is_default}
                            {...{
                                moreOptionsFlag,
                                setMoreOptionsFlag
                            }}
                        />
                </FlexboxGrid.Item>}
             
                {((service || !isSupplierTrunk) && moreOptionsFlag) && <FlexboxGrid.Item>
                    <MoreOptionsForm
                        error={error}
                        setError={setError}
                        disabled = {disabled}
                        model={formMoreModel}
                        setMoreData={setMoreData}
                        initialValues = {initialValues}
                        parametersInfo = {servicePick(service, voiceParametersInfo, smsParametersInfo)}
                        {...{
                            service,
                            // jsonElementRef,
                            onPostbackTest,
                            isSupplierTrunk
                        }}
                    />
                </FlexboxGrid.Item>}
                {service && is_default && <FlexboxGrid.Item  style={{minWidth: 400}}>
                     <IvrForm
                        ivr_list={ivr_list}
                        initialValues={initialValues}
                        disabled={disabled}
                        formRef={updateFormRef3}
                        {...{
                            moreOptionsFlag,
                            setMoreOptionsFlag
                        }}
                    />
                    
                </FlexboxGrid.Item>}
            </FlexboxGrid>

            <FlexboxGrid>
                {isTransitTrunks && <FlexboxGrid.Item>
                    <Header>Non dialer traffic route 
                        {initialValues?.organic_bound ? <Whisper
                            speaker={<Tooltip>Bound</Tooltip>}
                            trigger="hover"
                        >
                            <Icon icon="link" style={{marginLeft: "5px"}}/>
                        </Whisper> : <></>}
                    </Header>

                    <NonDialerTrafficRoute
                        model={nonDialerTrafficRouteModel}
                        error={error}
                        disabled={disabled} 
                        formRef={updateFormRef4}
                        formDefaultValue={initialValues}
                    
                        setError={setError}
                    />
                </FlexboxGrid.Item>}
            </FlexboxGrid>
        </Form>
    );
};

const mapState = ({references})=> ({
    defaultSPKey: references.defaultSPKey
});

export default connect(mapState, null)(TrunkForm);


const DialerForm = ({formRef, disabled, initialValues, defaultSPKey, moreOptionsFlag, setMoreOptionsFlag, showOnlyMoreOption=false}) => {
    if (initialValues.ip == null) {
        delete initialValues.ip;
        delete initialValues.tprefix;
        delete initialValues.port;
        delete initialValues.prt_key;
    }

    return (
        <Form
            layout="inline"
            model={formDialerModel}
            ref={formRef}
            formDefaultValue={{prt_key: DEFAULT_PRT_KEY, sp_key: defaultSPKey, port: "5060", tprefix: "", ...initialValues }}
            style={{margin: "0px", width: "100%"}}
        >

            <StyledFormStretched>
                {!showOnlyMoreOption && <div className="column">
                    <Text name="ip" label="IP" disabled={disabled} labelWidth={65}/>
                    <div className="protocol">
                        <Protocols style={{width: "160px"}}/>
                        <Text name="port" label="Port" disabled={disabled} style={{width: "90px"}}  />
                    </div>

                    <Text name="tprefix" label="Techprefix" disabled={disabled} labelWidth={65}/>
                </div>}
                <div className="column">
                    <StyledButton
                        open
                        onClick={() => {
                            setMoreOptionsFlag(!moreOptionsFlag)
                        }}>
                        More options
                    </StyledButton>
                </div>
            </StyledFormStretched>
        </Form>
    );
};

const MoreOptionsForm = ({ service, error, model, jsonElementRef, setError, disabled, setMoreData, initialValues, onPostbackTest, parametersInfo, isSupplierTrunk=false}) => {

    const payoutModelList = [{
        name: service ? "Price per minute" : "Price per message",
        value: 1
    }, {
        name: "Revenue share",
        value: 2
    }];

    const postbackMethodData = [
        {
            name: "POST",
            value: "POST"
        },
        {
            name: "GET",
            value: "GET"
        }
    ];

    const [formData, setFormData] = useState(initialValues);
    const [showRevenue, setShowRevenue] = useState(initialValues?.payout_model === 2);
    const [payoutModel, setPayoutModel] = useState(initialValues.payout_model || 1);

    const errorObject = error["moreOptionsError"] || {};

    return (
        <Form
            model={model}
            layout="inline"
            formDefaultValue={{...initialValues}}
            formValue={formData}
            onChange={(value) => {
                const formValue = {...initialValues, ...value};

                const revenueIsNull = formValue?.payout_model === 2 && !formValue?.revenue_percent;
                const formCheck = model.check(value);
                const errors = {};

                if (revenueIsNull) {
                    errors.revenue_percent = "Required";
                }

                const formErrors = Object.keys(formCheck).reduce((data, key) => {
                    if (formCheck[key].hasError) {
                        data[key] = formCheck[key].errorMessage
                    }
                    return data;
                }, errors);

                setError((prevValue) => {
                    return {
                        ...prevValue,
                        "moreOptionsError": formErrors
                    }
                });
                setMoreData(value);
                setFormData(value);
            }}
            style={{margin: "0px", width: "100%"}}
            formError={errorObject}
        >
            <StyledFormStart>
                <div className="column">
                    <StyledContainerColumn>
                        <StyledContainerRow>
                            <StyledLabel compact display="block">
                                Postback URL
                                <Whisper
                                    placement="rightStart"
                                    trigger="hover"
                                    controlId="control-id-hover-enterable"
                                    speaker={parametersInfo}
                                    enterable
                                >
                                    <Icon icon="info"/>
                                </Whisper>
                            </StyledLabel>
                            <StyledButton
                                withoutMargin
                                className="right"
                                onClick={() => {
                                    onPostbackTest();
                                }}
                                disabled={!formData.postback_url || Object.keys(errorObject).length}
                            >
                                Test Postback
                            </StyledButton>
                        </StyledContainerRow>
                        <FormControl
                            accepter={Input}
                            componentClass="textarea"
                            className="textarea"
                            name="postback_url"
                            disabled={disabled}
                            placeholder="Enter your postback url here"
                        />
                        {!service && <>
                            <StyledLabel compact display="block">
                                Postback method
                            </StyledLabel>
                            <FormControl
                                accepter={SelectPicker}
                                data={postbackMethodData}
                                defaultValue="GET"
                                cleanable={false}
                                searchable={false}
                                labelKey="name"
                                valueKey="value"
                                name="postback_method"
                            />
                        </>}
                    </StyledContainerColumn>
                </div>
                {/* {!isSupplierTrunk && !service && <div className="column">
                    <StyledContainerRow>
                        <StyledLabel>Posback params</StyledLabel>
                        <div
                            id="jsoneditor"
                            className="jsoneditor-react-container"
                            ref={jsonElementRef}
                        />
                    </StyledContainerRow>
                </div>} */}
                {service && <div className="column">
                    <StyledContainerRow>
                        <StyledLabel>Payout model</StyledLabel>
                        <FormControl
                            accepter={SelectPicker}
                            data={payoutModelList}
                            name="payout_model"
                            labelKey="name"
                            valueKey="value"
                            disabled={disabled}
                            onChange={(value) => {
                                setShowRevenue(value === 2);
                                setPayoutModel(value);
                            }}
                            value={payoutModel}
                            defaultValue={1}
                            cleanable={false}
                            searchable={false}
                            placeholder="Select model"
                        />
                    </StyledContainerRow>
                </div>}
                {showRevenue && <div className="column">
                    <StyledContainerRow>
                        <StyledLabel>Percent from revenue</StyledLabel>
                        <FormControl
                            accepter={InputNumber}
                            name="revenue_percent"
                            disabled={disabled}
                            placeholder="Enter your revenue"
                            step={0.1}
                            min={0}
                            max={100}
                        />
                    </StyledContainerRow>
                </div>}
            </StyledFormStart>
        </Form>
    );
};


// const PostbackSmsDialerForm = ({ service, error, setError, disabled, setMoreData, initialValues, onPostbackTest}) => {

//     const payoutModelList = [{
//         name: service ? "Price per minute" : "Price per message",
//         value: 1
//     }, {
//         name: "Revenue share",
//         value: 2
//     }];

//     const [formData, setFormData] = useState(initialValues);
//     const [showRevenue, setShowRevenue] = useState(initialValues?.payout_model === 2);
//     const [payoutModel, setPayoutModel] = useState(initialValues.payout_model || 1);

//     const parametersInfo = (
//         <Popover title="Parameters">
//             <p><b>{"{{smsid}}"}</b> - SMS id</p>
//             <p><b>{"{{senderid}}"}</b> - Sender id</p>
//             <p><b>{"{{smstime}}"}</b> - SMS time</p>
//             <p><b>{"{{payout}}"}</b> - Payout</p>
//             <p><b>{"{{called_number}}"}</b> - Called number</p>
//             <p><b>{"{{smstext}}"}</b> - SMS text</p>
//         </Popover>
//     );

//     return (
//         <Form
//             layout="inline"
//             formDefaultValue={{...initialValues}}
//             formValue={formData}
//             model={formMoreModel}
//             onChange={(value) => {
//                 const formValue = {...initialValues, ...value};

//                 const revenueIsNull = formValue?.payout_model === 2 && !formValue?.revenue_percent;
//                 const formCheck = formMoreModel.check(value);
//                 const errors = {};

//                 if (revenueIsNull) {
//                     errors.revenue_percent = "Required";
//                 }

//                 const formErrors = Object.keys(formCheck).reduce((data, key) => {
//                     if (formCheck[key].hasError) {
//                         data[key] = formCheck[key].errorMessage
//                     }
//                     return data;
//                 }, errors);

//                 setError(formErrors);
//                 setMoreData(value);
//                 setFormData(value);
//             }}
//             style={{margin: "0px", width: "100%"}}
//             formError={error}
//         >
//             <StyledFormStart>
//                 <div className="column">
//                     <StyledContainerColumn>
//                         <StyledContainerRow>
//                             <StyledLabel compact display="block">
//                                 Postback URL
//                                 <Whisper
//                                     placement="rightStart"
//                                     trigger="hover"
//                                     controlId="control-id-hover-enterable"
//                                     speaker={parametersInfo}
//                                     enterable
//                                 >
//                                     <Icon icon="info"/>
//                                 </Whisper>
//                             </StyledLabel>
//                             <StyledButton
//                                 withoutMargin
//                                 className="right"
//                                 onClick={() => {
//                                     onPostbackTest();
//                                 }}
//                                 disabled={!formData.postback_url || Object.keys(error).length}
//                             >
//                                 Test Postback
//                             </StyledButton>
//                         </StyledContainerRow>
//                         <FormControl
//                             accepter={Input}
//                             componentClass="textarea"
//                             className="textarea"
//                             name="postback_url"
//                             disabled={disabled}
//                             placeholder="Enter your postback url here"
//                         />
//                     </StyledContainerColumn>
//                 </div>
//             </StyledFormStart>
//         </Form>
//     );
// };


const IvrForm = ({ formRef, ivr_list, disabled, initialValues, moreOptionsFlag, setMoreOptionsFlag}) => {
    const ivrData = ivr_list.map(ivr => {
        return {
            value: ivr.id,
            label: ivr.name
        }
    });
    const emptyIvr = {
        value: null,
        label: ""
    };

    const [currentIvr, setCurrentIvr] = useState(initialValues.ivr_id);

    const sortedIvrList = [...ivrData, emptyIvr];
    sortedIvrList.sort((a, b) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0;
    });

    return (
        <>
            <GlobalStyle/>
            <CenteredForm
                // layout="inline"
                // model={formMoreOptionsModel}
                ref={formRef}
                formDefaultValue={{...initialValues}}
                style={{width: "100%"}}
            >
                <StyledFormStretched>
                    {/* <div className="column">
                        <StyledButton
                            under
                            onClick={() => {
                                setMoreOptionsFlag(!moreOptionsFlag)
                            }}>
                            More options
                        </StyledButton>
                    </div> */}
                    <div className="column">
                        <StyledContainerRow>
                            <StyledLabel display="block" compact width={100}>Select IVR</StyledLabel>
                            <FormControl
                              className="ivr_select"
                              accepter={SelectPicker}
                              data={sortedIvrList}
                              name="ivr_id"
                              onChange={(value) => {
                                  setCurrentIvr(value);
                              }}
                              width={300}
                              value={currentIvr}
                              disabled={disabled}
                              cleanable={false}
                              placeholder={<>&#8203;</>}
                            />
                        </StyledContainerRow>
                    </div>
                </StyledFormStretched>
            </CenteredForm>
        </>
    );
};


const ContentSupplierForm = ({service, error, model, setError, disabled, formatMessage, initialValues, setMoreData}) => {
    const contentTypeData = [
        {
            name: "text/plain",
            value: "text/plain"
        },
        {
            name: "text/html",
            value: "text/html"
        },
        {
            name: "application/json",
            value: "application/json"
        }
    ];

    const [formData, setFormData] = useState(initialValues);

    const errorObject = error["contentSupplierError"] || {};

    return (<>
        <Form
            model={model}
            layout="inline"
            formDefaultValue={{...initialValues}}
            formValue={formData}
            onChange={(value) => {
                const formCheck = model.check(value);
                const errors = {};

                const formErrors = Object.keys(formCheck).reduce((data, key) => {
                    if (formCheck[key].hasError) {
                        data[key] = formCheck[key].errorMessage
                    }
                    return data;
                }, errors);

                setError((prevValue) => {
                    return {
                        ...prevValue,
                        "contentSupplierError": formErrors
                    }
                });
                setMoreData(value);
                setFormData(value);
            }}
            style={{margin: "0px", width: "100%"}}
            formError={errorObject}
        >
            <StyledContainerColumn>
                <StyledContainerRow maxWidth={180} margin="20px 0 20px 32px">
                    <StyledLabel compact display="block">
                        <Whisper
                            placement="rightStart"
                            trigger="hover"
                            controlId="control-id-hover-enterable"
                            speaker={
                                <Popover>
                                    You can copy postback URL by clicking on this button
                                </Popover>
                            }
                            enterable
                        >
                            <Icon icon="info"/>
                        </Whisper>
                    </StyledLabel>
                    <StyledButton
                        withoutMargin
                        className="right"
                        onClick={() => {
                            copyToClipboard(initialValues.postback_info);
                            Alert.success(formatMessage(m.copied));
                        }}
                        disabled={!initialValues.postback_info || Object.keys(errorObject).length}
                    >
                        Get postback URL
                    </StyledButton>
                </StyledContainerRow>
                <StyledContainerRow maxWidth={220} margin="0 0 10px 70px">
                    <StyledLabel width={100} margin="0px">
                        Content Type
                    </StyledLabel>
                    <FormControl
                        accepter={SelectPicker}
                        data={contentTypeData}
                        cleanable={false}
                        searchable={false}
                        labelKey="name"
                        valueKey="value"
                        name="postback_response_mime_type"
                    />
                </StyledContainerRow>
                <StyledContainerRow maxWidth={320} margin="0 0 0 70px">
                    <StyledLabel width={100} margin="0 0 5px 0">
                        Content Body
                    </StyledLabel>
                    <FormControl
                        accepter={Input}
                        componentClass="textarea"
                        className="textarea"
                        name="postback_response_body"
                        disabled={disabled}
                        placeholder="Enter your content body"
                    />
                </StyledContainerRow>
            </StyledContainerColumn>
        </Form>
    </>)
};

const NonDialerTrafficRoute = ({
    formRef,
    model,
    error,
    disabled,
    formDefaultValue,

    setError
}) => {

    const [formValue, setFormValue] = useState({
        organic_ip: formDefaultValue?.organic_ip,
        organic_port: formDefaultValue?.organic_port,
        organic_login: formDefaultValue?.organic_login,
        organic_password: formDefaultValue?.organic_password
    });

    const errorObject = error["trafficRouteError"] || {};

    return <Form
        ref={formRef}
        model={model}
        layout="inline"
        formDefaultValue={formDefaultValue}
        formValue={formValue}
        onChange={(value) => {
            const formCheck = model.check(value);
            const errors = {};

            const formErrors = Object.keys(formCheck).reduce((data, key) => {
                if (formCheck[key].hasError) {
                    data[key] = formCheck[key].errorMessage
                }
                return data;
            }, errors);

            setError((prevValue) => {
                return {
                    ...prevValue,
                    "trafficRouteError": formErrors
                }
            });
            setFormValue(value);
            const isAllEmpty = Object.keys(value).every((key) => {
                return !value[key];
            });

            if (formRef.current && formRef.current.cleanErrors && isAllEmpty) {
                formRef.current.cleanErrors();
                setError((prevValue) => {
                    const {trafficRouteError: _, ...restPrevValue} = prevValue;
                    return {
                        ...restPrevValue
                    }
                });
            }
        }}
        style={{margin: "0px", width: "100%"}}
        formError={errorObject}
    >
        <StyledContainerRow start>
            <StyledContainerRow margin="0 0 0 70px">
                <StyledLabel width={30} margin="0 0 5px 0">
                    IP
                </StyledLabel>
                <FormControl
                    accepter={Input}
                    name="organic_ip"
                    disabled={disabled}
                    errorPlacement="topRight"
                    placeholder="Enter IP"
                    style={{width: 130}}
                />
            </StyledContainerRow>
            <StyledContainerRow margin="0 0 0 20px">
                <StyledLabel width={35} margin="0 0 5px 0">
                    Port
                </StyledLabel>
                <FormControl
                    accepter={Input}
                    name="organic_port"
                    disabled={disabled}
                    errorPlacement="topRight"
                    placeholder="Enter port"
                    style={{width: 90}}
                />
            </StyledContainerRow>
            <StyledContainerRow margin="0 0 0 20px">
                <StyledLabel width={40} margin="0 0 5px 0">
                    Login
                </StyledLabel>
                <FormControl
                    accepter={Input}
                    name="organic_login"
                    disabled={disabled}
                    errorPlacement="topRight"
                    placeholder="Enter login"
                    style={{width: 185}}
                />
            </StyledContainerRow>
            <StyledContainerRow margin="0 0 0 20px">
                <StyledLabel width={70} margin="0 0 5px 0">
                    Password
                </StyledLabel>
                <FormControl
                    accepter={Input}
                    name="organic_password"
                    disabled={disabled}
                    errorPlacement="topRight"
                    placeholder="Enter password"
                    style={{width: 125}}
                />
            </StyledContainerRow>
        </StyledContainerRow>
    </Form>
};

const GlobalStyle = createGlobalStyle`
    .rs-picker-menu .ReactVirtualized__Grid__innerScrollContainer > div:nth-child(1) .rs-picker-select-menu-item {
        padding: 18px !important;
    }
`;

const TrafficLimitForm = styled(Form)`
    && {
        display: flex;
    }

    && .rs-input[disabled], && .rs-picker-select.rs-picker-disabled, && .rs-picker-select.aria-disabled {
        color: inherit;
        background-color: inherit;
        opacity: 0.6;
    }

    && .rs-form-control-wrapper .rs-error-message-placement-top-end .rs-error-message {
        bottom: auto;
        font-size: 10px;
        padding: 0 2px;
        border: none;
        box-shadow: none;
        right: 6px;
        top: -4px;
    }

    &&& .rs-input, &&& .rs-picker-select .rs-btn {
        ${props => 
            props.styleClosed &&
            css`
                background-color: #d3d3d9 !important;
                border-color: #b4b4b4;
            `
        }
    }
`;

const StyledField = styled.div`
    && {
        margin-right: 20px;
    }
`;


const StyledButtonContainer = styled.div`
`;

const ServiceButton = styled(Button)`
    height: 34px;
    width: 110px;
`;

const SaveButton = styled(Button)`
    && {
        margin-right: 10px;
        width: 34px;
        height: 34px;
        padding: 0px;
        border-color: var(--color-secondary) !important;
        color: var(--color-secondary) !important;
    }
`;

const CancelButton = styled(Button)`
    && {
        width: 34px;
        height: 34px;
        padding: 0px;
        border-color: red !important;
        color: red !important;
    }
`;

const EditButton = styled(Button)`
    && {
        margin-right: 10px;
        width: 34px;
        height: 34px;
        padding: 0px;
        border-color: var(--color-primary) !important;
        color: var(--color-primary) !important;
    }
`;


const AddNewButton = styled(Button)`
    margin-left: 34px;
`;

const StyledListItem = styled(List.Item)`
    && {
        padding-top: 0px;
    }
`;

const StyledFlexboxGrid = styled(FlexboxGrid)`
    && {
        margin-top: ${props => props.mt || 20}px;
        margin-left: ${props => props.ml || 80}px;
        margin-bottom: ${props => props.mb || 10}px;
    }

    && .rs-flex-box-grid-item-2:not(:last-child) {
        margin-right: 107px;
    }
`;

const StyledList = styled(List)`
    && {
        box-shadow: none;
        margin-bottom: 10px;
    }

    && .rs-list-item {
        box-shadow: none;
    }

    && .rs-form-control-wrapper {
        margin-right: 20px;
    }

    &&& .rs-list-item .rs-form-group {
        margin-bottom: 0;
    }

    && .rs-list-item .separator {
        background: white;
        color: gray;
        // border: 1px solid #E5E5EA;
        // border-right: 0;
        // border-left: 0;
        height: 36px;
        width: 34px;
        position: relative;
        display: inline-block;
        padding-top: 6px;
        padding-left: 13px;
        top: -1px;
    }
    && .rs-list-item .status {
        float: left;
        margin-top: 6px;
    }

    && .rs-list-item .index {
        background: white;
        color: gray;
        // border: 1px solid #E5E5EA;
        // border-right: 0;
        height: 36px;
        width: 34px;
        position: relative;
        display: inline-block;
        float: left;
        padding-top: 6px;
        padding-left: 13px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    && .rs-list-item .delete {
        display: inline-block;
        position: absolute;
        right: -3px;
        top: 12px;
        cursor: pointer;
        border: 1px black;
        background: white;
        border-radius: 1px;
        width: 15px;
        height: 15px;
    }

    && .rs-list-item .delete_traffic {
        display: inline-block;
        position: absolute;
        margin-left: 5px;
        top: 12px;
        cursor: pointer;
        border: 1px black;
        background: white;
        border-radius: 1px;
        width: 15px;
        height: 15px;
    }

    && .rs-list-item .delete::before {
        display: flex;
        justify-content: center;
        width: 12px;
        flex-direction: row;
        align-items: center;
        align-content: center;
    }
`;


const CenteredForm = styled(Form)`
    && {
        width: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const MarginContainer = styled.div`
    &&, && .rs-checkbox, && .rs-checkbox-checker{
        display: inline;
    }
    && span {
        margin-left: 10px;
    }
`;

const StyledLabel = styled.span`
    ${props => props.display === "block" ? css`
        display: block;
    ` : css`
        display: inline-block;
    `}
    
    ${props => !props.compact && css`
        width: 100%;
    `}

    ${props => props.width && css`
        min-width: ${props.width}px;
        max-width: ${props.width}px;
        width: ${props.width}px; 
    `}

    && {
        ${props => props.margin && css`
            margin: ${props.margin};
        `}
    }

    margin-right: 10px;
    margin-bottom: 5px;
    
    .rs-icon {
        margin-left: 10px;
    }
`;

const StyledContainerColumn = styled.div`
    && {
        display: flex;
        flex-direction: column;

        ${props => props.margin && css`
            margin: ${props.margin};
        `}
    }
    .rs-form-group {
        margin-bottom: 10px;
    }
    && .textarea {
        margin-bottom: 10px;
        width: 100%; 
        height: 80px;
        min-width: 400px;
        resize: auto;
    }
    && .ivr_select {
        width: 300px;
    }
`;

const StyledContainerRow = styled.div`
    && {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-bottom: 10px;
        ${props => props.start ? css`
            justify-content: flex-start;
        ` : css`
            justify-content: space-between;
        `}

        ${props => props.maxWidth && css`
            max-width: ${props.maxWidth}px; 
        `}

        ${props => props.margin && css`
            margin: ${props.margin};
        `}
    }
`;

const StyledGrid = styled(FlexboxGrid)`
    ${props => props.isMobile && css`
        &&& {
            flex-direction: column;
        }
    `}
`;

const StyledFormStretched = styled.div`
    && {
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    && .column {
        width: 100%;
    }
    && .column > .rs-form-group {
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        align-content: space-between;
    }
    && .column > .rs-form-group .rs-form-control-wrapper {
        width: 100%;
    }
    && .column > .rs-form-group input {
        width: 100%;
    }
    && .protocol {
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
`;

const StyledFormStart = styled.div`
    && {
        margin: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    && .column {
        width: 100%;
    }
`;

const StyledButton = styled(Button)`
    ${props => !props.withoutMargin && (!props.under  ? css`
        margin-top: 10px;
        ${props.open && "margin-bottom: 20px !important"};
    ` : css`
        margin-bottom: 20px !important;
    `)};
    width: 140px;
    .right {
      float: right;
    }
`;

const Header = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-main);
    margin-left: 40px;
    margin-bottom: 10px;
    margin-top: 20px;
    padding: 0px;
    div {
        display: inline-block;
        width: 50%;
    }
    div:last-child{
        text-align: right
    }
`;

const CustomPercentField = styled(CustomField)`
    && {
        width: 90px !important;
    }
`;

const FullWidthItem = styled(FlexboxGrid.Item)`
    && {
        width: 100%;
    }
`;

const TermPointItem = styled(FlexboxGrid.Item)`
    && {
        width: 100%;
        margin-bottom: 20px;

        .rs-form-group {
            display: flex;
            width: 100%;
            justify-content: center;
            margin-top: 0px;
        }
    }
`;