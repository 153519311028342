import React, {useState, useEffect} from "react";
import {FlexboxGrid} from "rsuite"
import PageHeader from "components/base/PageHeader"
import AccessListFilters from "./AccessListFilters"
import AccessListTable from "./AccessListTable"
import PanelLayout from "components/base/PanelLayout";
import ExportCsv from "components/ExportCsv";
import {connect} from "react-redux";
import {getAccessList} from "actions/access_list";
import {EUR_DEFAULT_CURRENCY_KEY, USD_DEFAULT_CURRENCY_KEY} from "const";
import {createGlobalStyle} from "styled-components"
import {servicePick} from "utils";
import {
    ACCESS_LIST_GET_LIST_API,
    SMS_ACCESS_LIST_GET_LIST_API,
    ACCESS_LIST_EXTENDED_GET_LIST_API,
    SMS_ACCESS_LIST_EXTENDED_GET_LIST_API
} from "const/apiMethods";
import {getReferencesForReports} from "actions/references";
import {servicePlanNoTrunkFilter} from "utils/filters";


const AccessList = ({
    page,
    count,
    per_page,
    service,
    accessList,
    accessListLoading,
    currency_list,
    paymentTermsList,
    supplierList,
    service_plan_list,
    getAccessList,
    getReferencesForReports
}) => {
    
    const [extended, setExtended] = useState(false);
    const minAtxDialersName = servicePick(service, "min_atx_numbers", "min_dialer_numbers");


    const defaultNotExtendedFilter = {
        [servicePick(service, "str", "origin")]: "",
        [servicePick(service, "str2", "destination")]: "",
        no_number: false,
        sp_key_list: null,
        cur_key: servicePick(service, USD_DEFAULT_CURRENCY_KEY, EUR_DEFAULT_CURRENCY_KEY),
        supplier_id_list: []
    };
    
    const defaultExtendedFilter = {
        [servicePick(service, "originator", "supplier_id_list")]: servicePick(service, "", []),
        [servicePick(service, "origin", "senderid")]: "",
        destination: "",
        min_numbers: null,
        [minAtxDialersName]: null
    };


    const defaultFilter = extended ? defaultExtendedFilter : defaultNotExtendedFilter;

    const [filters, setFilters] = useState(defaultFilter);
    const defaultNotExtendedSorting = servicePick(service, 
        {
            column: "access_origin",
            type: "asc"
        }, 
        {
            column: "origin",
            type: "asc"
        }
    );
    const defaultExtendedSorting = servicePick(service, 
        {
            column: "origin",
            type: "asc"
        },
        {
            column: "supplier",
            type: "asc"
        }
    ); 
    let defaultSort = extended ? defaultExtendedSorting : defaultNotExtendedSorting;

    const [sortState, setSortState] = useState(defaultSort);

    useEffect(() => {
        setFilters(defaultFilter);

        if (!service) {
            getReferencesForReports();
        }
    }, [service]);

    useEffect(() => {
        const filter = extended ? {} : defaultFilter;

        getAccessList(filter, service, 1, per_page, defaultSort, false, extended)
        setSortState(defaultSort);
        setFilters(defaultFilter);
    }, [extended]);


    const onChangeFilters = (filter) => {
        const filterOnlyExist = Object.keys(filter).reduce((result, key) => {
            if (key in defaultFilter) {
                result[key] = filter[key];
            }
            return result;
        }, {});

        setFilters({...filterOnlyExist});
        getAccessList(filterOnlyExist, service, 1, per_page, sortState, false, extended);
    };

    const getItems = (page, per_page, sort) => {
        getAccessList(filters, service, page, per_page, sort, false, extended);
    };

    const getDownloadParams = () => {
        const curMinNumbers = remainingDownloadFilters["min_numbers"];
        const curMinAtxNumbers = remainingDownloadFilters[minAtxDialersName];
        return {
            ...remainingDownloadFilters,
            ...(extended ? {
                min_numbers: curMinNumbers ? parseInt(curMinNumbers) : 0,
                [minAtxDialersName]: curMinAtxNumbers ? parseInt(curMinAtxNumbers) : 0
            } : {}),
            ...(downaloadSpKey ? {sp_key: downaloadSpKey} : {}),
            ...(!extended ? {sp_key_list: downloadSpKeyList} : {})
        }
    }

    const {sp_key: downaloadSpKey, ...remainingDownloadFilters} = filters;
    const downloadSpKeyList = !remainingDownloadFilters.sp_key_list 
        || !remainingDownloadFilters.sp_key_list.length
            ? null
            : remainingDownloadFilters.sp_key_list;

            
    const downLoadParams = getDownloadParams();

    const downloadMethod = !extended ? 
        service ? ACCESS_LIST_GET_LIST_API : SMS_ACCESS_LIST_GET_LIST_API :
        service ? ACCESS_LIST_EXTENDED_GET_LIST_API : SMS_ACCESS_LIST_EXTENDED_GET_LIST_API;
    const downloadFileName = !extended ? "accesses_list.csv" : "extended_accesses_list.csv";

    return (
        <>
            <PanelLayout>
                <GlobalStyle/>
                <PageHeader title="Access List">
                    <ExportCsv
                        method={downloadMethod}
                        params={{
                            filter: downLoadParams,
                            ...(!extended ? {no_number: filters.no_number} : {})
                        }}
                        title="Download"
                        fileName={downloadFileName}
                        style={{position: "relative", right: 15}}
                    />
                </PageHeader>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={24}>
                        <AccessListFilters
                            service={service}
                            onChange={onChangeFilters}
                            defaultFormValue={defaultFilter}
                            supplierList={supplierList}
                            currency_list={currency_list}
                            extended={extended}
                            setExtended={setExtended}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <AccessListTable
                    {...{
                        accessList,
                        service_plan_list,
                        accessListLoading,
                        page,
                        count,
                        per_page,
                        service,
                    }}
                    paymentTermsList={paymentTermsList}
                    getItems = {getItems}
                    onSort={(column, type) => {
                        setSortState({column, type})}
                    }
                    sortType={sortState.type}
                    sortColumn={sortState.column}
                    filters={filters}

                    extended={extended}
                    setExtended={setExtended}
                />

            </PanelLayout>
        </>
    )
};

const mapState = ( {auth, access_list, references} )=> ({
    accessList: access_list.items,
    count: access_list.count,
    service: auth.service,

    accessListLoading: access_list.loading,
    page: access_list.page,
    per_page: access_list.per_page,
    defaultSPKey: references.defaultSPKey,
    currency_list: references.currency_list,
    paymentTermsList: references.payment_terms_list,
    service_plan_list: references.service_plan_list.filter(servicePlanNoTrunkFilter),
    supplierList: !auth.service ? references["sms.supplier_list"] : []
});

export default connect(mapState, {
    getAccessList,
    getReferencesForReports
})(AccessList);

const GlobalStyle = createGlobalStyle`
    .rs-picker-check-menu-items {
        overflow-y: hidden !important;
        max-height: none !important;
    }
`;